import { Injectable } from '@angular/core';
import { Role, User } from '@core/model/user.model';
import { FlamingoHttpService } from '@flamingo/service/flamingo-http.service';
import { Company } from '@x/model/company.model';
import { BehaviorSubject, Observable, switchMap } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { MainService } from '../../app/children/main/main.service';
import { LoggedUser, Permissions } from '../model/logged-user.model';

@Injectable({
  providedIn: 'root',
})
export class LoggedUserService {
  private _user$: BehaviorSubject<LoggedUser | null> = new BehaviorSubject<LoggedUser | null>(null);
  public user$: Observable<LoggedUser> = this._user$.asObservable()
    .pipe(
      filter((user: LoggedUser | null): user is LoggedUser => !!user),
    );

  constructor(
    private flamingoHttpService: FlamingoHttpService,
    private mainService: MainService,
  ) {
  }

  get user(): LoggedUser | null {
    return this._user$.value;
  }

  public fetch(): Observable<LoggedUser> {
    let user: LoggedUser;
    return this.flamingoHttpService.get<User>(`/me`)
      .pipe(
        switchMap(u => this.flamingoHttpService.get<Company[]>(`/companies/me`)
          .pipe(
            tap(res => this.mainService.companies = res),
            // tap(c => this.mainService.company = c[0]),
            map(_ => u),
          )),
        tap(u => user = { ...u, role: { name: u.role, permissions: [] } } as LoggedUser),
        tap(_ => this.setPermission(user, this.mainService.company)),
        map(_ => user),
      );
  }

  setPermission(user: LoggedUser, c?: Company): void {
    user.role.permissions = [];
    switch (user.role.name) {
      case Role.USER:
        user.role.permissions.push(
          { name: Permissions.canManageQuestionnaire },
          { name: Permissions.canManageSubscriptions },
          { name: Permissions.canManageDocuments },
          { name: Permissions.canManageEvent },
          { name: Permissions.canManageSelf },
          { name: Permissions.canManageSites },
        );
        if (c?.mainUserId === user.id) {
          user.role.permissions.push(
            { name: Permissions.canManageUsers },
          );
        }
        if (this.mainService.subscription) {
          // TODO: add subscription permission?
        }
        break;
      case Role.ACCOUNTANT:
        user.role.permissions.push(
          { name: Permissions.canManageQuestionnaire },
          { name: Permissions.canManageSubscriptions },
          { name: Permissions.canManageDocuments },
          { name: Permissions.canManageEvent },
          { name: Permissions.canManageSelf },
          { name: Permissions.canManageCompanies },
        );
        if (c?.mainUserId === user.id) {
          user.role.permissions.push(
            { name: Permissions.canManageUsers },
          );
        }
        break;
      default:
        user.role.permissions.push(
          { name: Permissions.canManageSelf },
        );
        break;
    }

    this._user$.next(user);
  }

  public clear(): void {
    this._user$.next(null);
  }

  can(permission: Permissions | Permissions[]): boolean {
    if (!Array.isArray(permission)) {
      permission = [permission];
    }
    return permission.every(p => this.user?.role?.permissions.map(v => v.name).includes(p));
  }

  canAtLeastOne(permission: Permissions | Permissions[]): boolean {
    if (!Array.isArray(permission)) {
      permission = [permission];
    }
    return permission.some(p => this.user?.role?.permissions.map(v => v.name).includes(p));
  }

  cannot(permission: Permissions | Permissions[]): boolean {
    if (!Array.isArray(permission)) {
      permission = [permission];
    }
    return !permission.every(p => this.user?.role?.permissions.map(v => v.name).includes(p));
  }

}
