import { BaseUser, Role } from '@core/model/user.model';

export interface LoggedUser extends BaseUser {
  readonly fullName?: string;
  readonly role: LoggedUserRole;
}

export interface LoggedUserRole {
  name: Role;
  permissions: UserPermission[];
}

export interface UserPermission {
  readonly name: string;
}

export enum Permissions {
  // USER
  canManageQuestionnaire = 'canManageQuestionnaire',
  canManageDocuments = 'canManageDocuments',
  canManageUsers = 'canManageUsers',
  canManageSubscriptions = 'canManageSubscriptions',
  canManageEvent = 'canManageEvent',
  canManageSites = 'canManageSites',

  // ACCOUNTANT
  canManageCompanies = 'canManageCompanies',

  // DEFAULT
  canManageSelf = 'canManageSelf',
}
