import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { FlamingoHttpService } from '@flamingo/service/flamingo-http.service';
import { Company } from '@x/model/company.model';
import { SubscriptionModel } from '@x/model/subscription.model';
import { BehaviorSubject, Observable, of, skip, switchMap } from 'rxjs';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { WorkflowStatusUi } from '../../../@core/model/workflow-status-ui.model';

const LOCAL_STORAGE_COMPANY_ID = 'selectedCompanyId';
const LOCAL_STORAGE_SUBSCRIPTION_ID = 'subscriptionId';

@Injectable({
  providedIn: 'root',
})
export class MainService {

  private _companies$ = new BehaviorSubject<Company[]>([]);
  public companies$ = this._companies$.asObservable();
  private _company$ = new BehaviorSubject<Company | undefined>(undefined);
  public company$ = this._company$.asObservable();
  private _subscription$ = new BehaviorSubject<SubscriptionModel | undefined>(undefined);
  public subscription$ = this._subscription$.asObservable()
    .pipe(
      filter((item: SubscriptionModel | undefined): item is SubscriptionModel => item !== undefined),
    );
  private _workflowStatusUi$ = new BehaviorSubject<WorkflowStatusUi | undefined>(undefined);
  public workflowStatusUi$ = this._workflowStatusUi$.asObservable()
    .pipe(
      filter((item: WorkflowStatusUi | undefined): item is WorkflowStatusUi => item !== undefined),
    );

  private _loader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loader$: Observable<boolean> = this._loader$.asObservable();

  get companies(): Company[] {
    return this._companies$.value;
  }

  set companies(value: Company[]) {
    this._companies$.next(value);
  }

  get company(): Company | undefined {
    return this._company$.value;
  }

  set company(value: Company) {
    if (value) {
      localStorage.setItem(LOCAL_STORAGE_COMPANY_ID, `${value.id}`);
    } else {
      localStorage.removeItem(LOCAL_STORAGE_COMPANY_ID);
    }

    this._company$.next(value);
  }

  get subscription(): SubscriptionModel | undefined {
    return this._subscription$.value;
  }

  set subscription(value: SubscriptionModel | undefined) {
    if (value) {
      localStorage.setItem(LOCAL_STORAGE_SUBSCRIPTION_ID, `${value.id}`);
    } else {
      localStorage.removeItem(LOCAL_STORAGE_SUBSCRIPTION_ID);
    }

    this._subscription$.next(value);
  }

  get workflowStatusUi(): WorkflowStatusUi | undefined {
    return this._workflowStatusUi$.value;
  }

  set workflowStatusUi(value: WorkflowStatusUi | undefined) {
    this._workflowStatusUi$.next(value);
  }

  get loader(): boolean {
    return this._loader$.value;
  }

  set loader(show: boolean) {
    this._loader$.next(show);
  }

  constructor(
    router: Router,
    private http: FlamingoHttpService,
  ) {
    this.company$
      .pipe(
        distinctUntilChanged(),
        filter((company: Company | undefined): company is Company => company !== undefined),
        switchMap(company => this.getSubscriptionForCompany(company)),
        tap(subscription => this.subscription = subscription),
        skip(1),
        switchMap(_ => router.navigate(['/'], { queryParams: { t: Date.now() } })),
      )
      .subscribe();

    this.companies$
      .pipe(
        filter(companies => !!companies.length),
        map(companies => {
          const selectedCompany = localStorage.getItem(LOCAL_STORAGE_COMPANY_ID);
          const company = companies.find(c => c.id === Number(selectedCompany));
          return company || companies[0];
        }),
        tap(company => this.company = company),
      )
      .subscribe();
  }

  refreshSubscription(): Observable<SubscriptionModel | null> {
    if (this.subscription) {
      return this.http.get<SubscriptionModel>(`/subscriptions/${this.subscription!.id}`)
        .pipe(
          tap(subscription => this.subscription = subscription),
        );
    }
    return of(null);
  }

  setSubscriptionById(company: Company, subscriptionId: number): Observable<SubscriptionModel | undefined> {
    return this.http.get<SubscriptionModel[]>(`/companies/${company.id}/subscriptions`)
      .pipe(
        map(res => this.subscription = res.find(s => s.id === subscriptionId)),
      );
  }

  private getSubscriptionForCompany(company: Company): Observable<SubscriptionModel | undefined> {
    return this.http.get<SubscriptionModel[]>(`/companies/${company.id}/subscriptions`)
      .pipe(
        map(res => {
          const selectedSubscriptionId = Number(localStorage.getItem(LOCAL_STORAGE_SUBSCRIPTION_ID));
          const subscription = res.find(s => s.id === selectedSubscriptionId);
          return subscription || res[0];
        }),
      );
  }

}
